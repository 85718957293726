/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: LoginForm
 *
 * Purpose: hold form info for login (copied from another file)
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 18Jul2023 craig File created.
 * 03Jul2023 sasjxa add bootstrap styling
 * 10Aug2023 sasjxa add setting of showDropdown
 * 16Nov2023 sasjxa enlarge form fields
 * 19Dec2023 sasjxa add error handling to direct to login page
 * 03Jan2024 craig  added location.state.from.pathname to see if we need to
 *                  redirect to page user tried to access
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 20Mar2024 sasjxa eliminate show/hide error (MPM-5414)
 * 30Apr2024 sasjxa fix button text centering
 * 04Jun2024 craig  remove access_token cookie
 * 05Jun2024 craig  Added code handle DISABLED/LOCKED/EXPIRED accounts
 * 16Aug2024 craig  Changed check from jwt to userid
 * 22Aug2024 craig  Changed to usePDSContext
 * 31Oct2024 craig  Added Spinner to button for waiting for login response
 * 18Dec2024 sasjxa add cursor pointer to forgot link
 * 02Jan2025 craig  Removed redirect to home page after log in
 \******************************************************************************/
import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik} from "formik";
// @ts-ignore
import styled from "styled-components";
import {PDSErrorResponse, useRequest} from "../../helper/useRequest";
import {ContentContainer, FormLayoutColumn,} from "../styled/StyledComponents";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {useLocation, useNavigate} from "react-router-dom";
import {IUser} from "../../model/user/IUser";
import {BASE_PATH} from "../../constants";
import {AxiosError} from "axios";
import {usePDSContext} from "../../context/PDSContext";
import {ClearAndSubmitButtonContainer} from "../buttonContainer/ClearAndSubmitButtonContainer";

const SignInButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;


    @media (min-width: 1024px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border-top: 1pt solid ${COLORS.access_data_gray};
        padding-top: 1.375rem;


        button:first-of-type {
            margin-bottom: 0;
            text-align: center;
        }
    }
`;

const PasswordRow = styled("div")`
    width: 475px;
    display: flex;

    a {
        font-size: ${FONT_SIZES.px14};
        padding-top: 3%;
        padding-right: 1%;
        border-top: 1px solid ${COLORS.form_button_grey} !important;
        border-right: 1px solid ${COLORS.form_button_grey} !important;
        border-bottom: 1px solid ${COLORS.form_button_grey} !important;
        border-left: 0;
        cursor: pointer;
    }
`;

/*const CapslockParagraph = styled("p")`
    color: red;
    font-weight: bold;
`;*/

interface LoginInProps {
    showHide?: any
    showDropdown?: any
}

export const LoginForm: React.FC<LoginInProps> =

    ({showHide, showDropdown}) => {
        const navigate = useNavigate();
        const {setSessionUser} = usePDSContext();

        const location = useLocation();
        const [loginMsg] = useState<string | undefined>("");
        const [capslockText, setCapslockText] = useState("");

        //this will disable the submit button while waiting for the response after submit
        const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);


        /**
         * handle error - unsuccessful login1`
         * @param error
         */
        const handleError = (error: AxiosError<PDSErrorResponse>) => {
            if (showHide != null) {
                showHide();
            }
            if (error.response?.data.detail === 'ACTIVE') {
                navigate(BASE_PATH + "login?error=true");
            } else if (error.response?.data.detail === 'EXPIRED') {
                navigate(BASE_PATH + "forgotPassword/EXPIRED");
            } else if (error.response?.data.detail === 'LOCKED') {
                navigate(BASE_PATH + "forgotPassword/LOCKED");
            } else if (error.response?.data.detail === 'INACTIVE') {
                navigate(BASE_PATH + "forgotPassword/INACTIVE");
            } else if (error.response?.data.detail === 'DISABLED') {
                navigate(BASE_PATH + "contactUs/" + error.response?.data.title);
            } else {
                //should only happen if user had not username or expire record
                navigate(BASE_PATH + "login?internalerror=true");
            }
            setIsWaitingForResponse(false);
        }

        /**
         * handle success call to login
         * @param user - returned user
         */
        const handleSuccess = (user: IUser) => {
            if (user) {
                //if (user.JwtToken !== '' && user.UserID > 0) {
                if (user.userID > 0) {
                    //setCookie("access_token", user.jwtToken, {path: '/'});
                    setSessionUser(user);
                    //setSessionCookie(user);
                    //check if we have the state.from in the location object.  If so, we are trying to get to a
                    //particular page and need to redirect there
                    if (location.state !== null && location.state.from !== null && location.state.from.pathname.length > 0) {
                        window.location.href = location.state.from.pathname;
                    } /*else {
                        window.location.href = "/";
                    }*/
                    if (showDropdown != null) {
                        showDropdown();
                    }
                }
            }
            setIsWaitingForResponse(false);
        }

        /**
         * handle forgot password
         */
        const handleForgotPassword = () => {
            if (showHide != null) {
                showHide();
            }
            navigate(BASE_PATH + "forgotPassword");

        }

        /**
         * setup API call to validate username and password
         */
        const loginURL = process.env.REACT_APP_API_URL + "/api/auth/login";
        const [requestState, setLoginUser] = useRequest({
            url: loginURL,
            method: "post",
            withCredentials: true,
            initialIsLoading: true,
            onError: handleError,
            onSuccess: handleSuccess
        })

        /**
         * validation schema
         */
        const schema = Yup.object().shape({
            username: Yup.string().required().email("You have entered an incorrect username/password."),
            password: Yup.string().required("You have entered an incorrect username/password."),
        });

        /**
         * build the form with formik
         */
        const buildForm = () => {
            return (
                <Formik
                    validationSchema={schema}

                    onSubmit={async (values) => {
                        setLoginUser(JSON.stringify({username: values.username, password: values.password}));
                        setIsWaitingForResponse(true);
                    }}
                    initialValues={{
                        username: '',
                        password: '',
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          touched,
                          errors,
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <FormLayoutColumn className="login-form">

                                <Row className="mb-3 w-100 ">
                                    <Form.Group as={Col} controlId="formBasicEmail">
                                        <Form.Control required className="no-rounded-border  login-email"
                                                      name={"username"}
                                                      size="lg"
                                                      type="email"
                                                      placeholder="Email"
                                                      isValid={touched.username && !errors.username}

                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted">Enter your email address.</Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email address
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"username"}
                                                      render={msg => <div className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3 w-100">
                                    <Form.Group as={Col} controlId="formBasicPassword">
                                        <PasswordRow className="mb-1 ">
                                            <Form.Control required className="no-rounded-border login-password"
                                                          name={"password"}
                                                          type="password"
                                                          size="lg"
                                                          placeholder="Password"
                                                          isValid={touched.password && !errors.password}
                                                          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                              const key: string = e.key;
                                                              if (key.length === 1 && key >= 'A' && key <= 'Z' && !e.shiftKey) {
                                                                  setCapslockText('Caps Lock is on ');
                                                              } else {
                                                                  setCapslockText("");
                                                              }

                                                          }}
                                                          onChange={handleChange}/>
                                            <a className="linkstyle  center "
                                               onClick={() => handleForgotPassword()}>Forgot?</a>
                                        </PasswordRow>
                                        <Form.Text className="text-muted">Enter the password that accompanies your email
                                            address.</Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a password
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"password"}
                                                      render={msg => <div className={"form-error-msg"}>{msg}</div>}/>

                                    </Form.Group>
                                </Row>
                            </FormLayoutColumn>
                            <SignInButtonRow>
                                <ClearAndSubmitButtonContainer submitButtonText={"Sign In"} enableClear={false}
                                                               isSubmitting={isWaitingForResponse}/>
                            </SignInButtonRow>
                        </Form>
                    )}
                </Formik>
            )
        }

        /**
         * return html
         */
        return (
            <ContentContainer>
                <Row>
                    <div className={"error-message"}>{loginMsg}</div>
                </Row>
                <FormLayoutColumn name={"formLayoutColumn"}>
                    {buildForm()}
                </FormLayoutColumn>
            </ContentContainer>
        );

    }
