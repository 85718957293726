/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AccessDataStudies
 *
 * Purpose: component to show access data studies
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 08Sep2023 sasjxa file created
 * 06Feb2024 sasjxa parse text to handle highlighted text
 * 21Feb2024 sasjxa correct upload date (MPM-5396)
 * 29Feb2024 sasjxa refactor to display individual study info (MPM-5392)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 14Apr2024 craig  Cleaned up some errors that show up in the console
 * 11Jun2024 craig  Removed user prop to AccessDataDetailsAndFiles
 * 03Jan2024 craig  add sessionUser to useMemo to get donations, so when user logs
 *                  in, they have the download links
 * \******************************************************************************/
import React, {useMemo, useState} from "react";

import {Col, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {IUser} from "../../model/user/IUser";
import styled from "styled-components";
import {IAccessData} from "../../model/donations/IAccessData";
import {IAccessSearchParams} from "../../model/access/IAccessSearchParams";
import {AccessDataStudyInfo} from "./AccessDataStudyInfo";
import axios, {AxiosError} from "axios";
import {usePDSContext} from "../../context/PDSContext";

const AccessDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 95%;
  margin: 0 auto;
  /*  max-width: 1440px; */
`;

const DonationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;

  /*  max-width: 1440px; */
`;

const AllStudies = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #F3F3F3;
`;

interface AccessDataStudiesProps {
    searchParams: IAccessSearchParams,
    isAccessData: boolean
}

export const AccessDataStudies: React.FC<AccessDataStudiesProps> = ({
                                                                        searchParams, isAccessData
                                                                    }: AccessDataStudiesProps) => {

    const {sessionUser} = usePDSContext();
    const [dashboardMsg, setDashboardMsg] = useState<string>("");

    const [donations, setDonations] = useState<IAccessData[]>([]);
    const [numDonations, setNumDonations] = useState<number>(0)


    /**
     * handle error, need to add message
     *
     * @param error
     */
    const buildError = (error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            //console.log(error.response.data);
        } else {
            //console.log(error.toString);
        }
    }

    /**
     * setup api call to get donations
     */
    const dsaURL = process.env.REACT_APP_API_URL + "/api/accessdata/results";
    const [requestState, getDonations] = useRequest({
        url: dsaURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError
    });
    const {isLoading, data, error} = requestState;

    /**
     * useMemo called when data returns from api
     */
    useMemo(() => {
        if (data !== null) {
            setDonations(data);
        }
    }, [data])

    /**
     * useMemo called when donations changes
     */
    useMemo(() => {
        if (donations !== null) {
            setNumDonations(donations.length);
        }
    }, [donations])

    /**
     * call the api when the searchParams or the sessionUser changes
     */
    useMemo(() => {
        getDonations(JSON.stringify(searchParams));
    },[searchParams, sessionUser]);

    /**
     * build the donation results
     */
    const buildResults = () => {
        return (
            <>
                <AccessDataContainer key={1} className=" mt-4 mb-2 ">
                    <>
                        <AllStudies className={"  mb-4"}>
                            <span className={"share-title "}>{donations.length} Studies | All Studies</span>
                        </AllStudies>
                        {
                            donations?.map((donation: IAccessData) => {
                                return <DonationContainer key={donation.donationId} className={"mb-1"}>
                                        <AccessDataStudyInfo donation ={donation} isAccessData={isAccessData}/>
                                    </DonationContainer>

                            })
                        }
                    </>
                </AccessDataContainer>
            </>
        )
    }

    /**
     * return
     */
    return (
        <>
            <Row>
                <div className={"error-message"}>{dashboardMsg}</div>
            </Row>
            <Col className="" name={"formLayoutColumn"}>
                {isLoading &&
                    <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {data && buildResults()}
            </Col>
        </>

    );

}
